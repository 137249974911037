<template>
    <div class="o-layout o-layout--large">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <recess-button
                        :title="BUTTON_TEXT.goBack"
                        icon="back"
                        variant="variant4"
                        class="qa-go-back-button"
                        url="/portfolio"
                    />
                    <title-text :title="PROVIDER.Portfolio.PortfolioOverview.Title" />
                </div>

                <div class="col-12 mt-3">
                    <recess-card variant="variant1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-9">
                                    <div class="row my-0">
                                        <div
                                            v-if="accountDropdownIsVisible"
                                            class="w-20 mr-4"
                                        >
                                            <recess-multi-select
                                                :selected-options="selectedFilters.accountId"
                                                :options="accountOptions"
                                                :search-input="true"
                                                :single-select="true"
                                                :select-options-text="accountOptions && accountOptions[0].displayText"
                                                :placeholder="PROVIDER.Portfolio.PortfolioOverview.FilterInputs.SelectAccount.Placeholder"
                                                class="qa-account-select"
                                                @input="(newValue) => handleSelectedAccount(newValue)"
                                            />
                                        </div>

                                        <div class="w-20 mr-4">  
                                            <recess-multi-select
                                                :selected-options="selectedFilters.courseStatus"
                                                :options="courseStatusOptions"
                                                :search-input="false"
                                                :single-select="true"
                                                class="qa-course-status-select"
                                                @input="(newValue) => handleCourseStatus(newValue)"
                                            />
                                        </div>

                                        <div class="w-30 mr-4">
                                            <recess-search-input
                                                v-model.trim="selectedFilters.searchQuery"
                                                :placeholder="PROVIDER.Portfolio.PortfolioOverview.FilterInputs.SearchQueryByTitle.Placeholder"
                                                class="qa-course-list-serach-bar"
                                                @input="(newValue) => handleSearchQuery(newValue)"
                                            />
                                        </div>

                                        <div class="w-20 mr-4">
                                            <recess-multi-select
                                                :selected-options="selectedFilters.productType"
                                                :options="productTypesOptions"
                                                :search-input="false"
                                                :single-select="true"
                                                class="qa-course-product-type-select"
                                                @input="(newValue) => handleProductTypes(newValue)"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <div class="d-flex justify-content-end">
                                        <recess-button
                                            variant="secondary"
                                            :title="PROVIDER.Portfolio.PortfolioOverview.CreateCourseButton"
                                            class="qa-course-create-button"
                                            :url="{ name: 'course-create' }"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </recess-card>
                </div>
            </div>

            <div class="row">
                <course-list class="col-12" ref="myCourseList" :set-selected-filters="localFilters"/>
            </div>
        </div>
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { mapState, mapActions  } from 'vuex'
import { BUTTON_TEXT, API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import TitleText from '@/components/atoms/TitleText'
import CourseList from '@/components/organisms/Portfolio/CourseList'
import { getItems, getItemsFromSearchIndex } from '../../../../shared/api/SharedClient'
import UserService from '../../../../shared/services/UserService'

import filterMixin from '@/../../shared/mixins/filterMixin'

export default {
    name: 'PortfolioOverview',
    components: {
        TitleText,
        CourseList
    },
    mixins: [filterMixin],
    data() {
        return {
            PROVIDER,
            BUTTON_TEXT,
            userService: new UserService(),
            isAdministrator: false, 
            accountOptions: [{
                displayText: PROVIDER.Portfolio.PortfolioOverview.FilterInputs.SelectAccount.SelectOption.AllAccounts,
                value: ''
            }],
            courseStatusOptions: [
                {
                    displayText: PROVIDER.Portfolio.PortfolioOverview.FilterInputs.CourseStatus.Options.Active.Label,
                    value: 'active'
                },
                {
                    displayText: PROVIDER.Portfolio.PortfolioOverview.FilterInputs.CourseStatus.Options.Deleted.Label,
                    value: 'deleted'
                }
            ],
            productTypesOptions: [
                {
                    displayText: PROVIDER.Portfolio.PortfolioOverview.FilterInputs.SelectProductType.Options.All.Label,
                    value: 'All'
                },
                {
                    displayText: PROVIDER.Portfolio.PortfolioOverview.FilterInputs.SelectProductType.Options.Regular.Label,
                    value: 'Regular'
                },
                {
                    displayText: PROVIDER.Portfolio.PortfolioOverview.FilterInputs.SelectProductType.Options.Incompany.Label,
                    value: 'Incompany'
                },
                {
                    displayText: PROVIDER.Portfolio.PortfolioOverview.FilterInputs.SelectProductType.Options.Custom.Label,
                    value: 'Custom'
                }
            ],
            selectedFilters: {
                accountId: '',
                providerId: '',
                courseStatus: 'active',
                searchQuery: '',
                productType: 'All'
            },
            debounce: null
        }
    },
    computed: {
        ...mapState('providerModule', ['provider', 'selectedProvider']),
        isAdminHasSelectedProvider() {
            return this.isAdministrator && this.selectedFilters.providerId
        },
        isProvider() {
            return !this.isAdministrator && this.selectedFilters.providerId
        },
        isProviderAndCanViewAssortment() {
            return (
                !this.isAdministrator && this.provider && this.provider.canViewAssortmentInPorfolio
            )
        },
        accountDropdownIsVisible() {
          return (!this.isAdministrator && this.provider.canViewAssortmentInPorfolio) || (this.isAdministrator && this.selectedProvider.canViewAssortmentInPorfolio)
        }
    },
    watch: {
        async 'provider.canViewAssortmentInPorfolio'()
        {
                if (this.isProviderAndCanViewAssortment) 
                {
                    await this.setAccountOptions()
                } 
        },
        async 'selectedProvider.id'() {
                if(!this.isAdministrator)
                {
                    return
                }

                this.resetAccountId()
                this.updateProvider()
               
                if (!this.selectedFilters.providerId) {
                    this.handleSelectedAccount(null)
                }

                if 
                (
                    this.isAdministrator &&
                    this.selectedProvider &&
                    this.selectedFilters &&
                    this.selectedProvider.id !== this.selectedFilters.providerId &&
                    this.selectedFilters.providerId !== null
                ) 
                {
                    this.selectedFilters.accountId = this.accountOptions[0].value 
                }

                if(this.$route.query.accountId && this.selectedProvider) {
                    this.selectedFilters.accountId = this.$route.query.accountId
                }

                await this.setAccountOptions()
        },
    },
    async created() {
        this.selectedFilters.providerId = this.$route.query.providerId ?? this.selectedProvider.id
        this.localFilters.providerId = this.$route.query.providerId ?? this.selectedProvider.id

        this.selectedFilters.accountId = this.$route.query.accountId
        this.localFilters.accountId = this.$route.query.accountId

        this.isAdministrator = await this.userService.isAdministrator()

        this.fetchProvider(this.selectedFilters.providerId)
        await this.setAccountOptions()
    },
    methods: {
        ...mapActions('providerModule', ['fetchProvider']),
        updateProvider(){
            this.selectedFilters.providerId = this.selectedProvider.id
            this.setSpecificFilter('providerId', this.selectedFilters.providerId) // MD: this changes the mixin filter and triggers a network call to courses index
        },
        resetAccountId(){
            this.selectedFilters.accountId = null
        },
        async handleSearchQuery(newValue)
        {
            this.setSpecificFilter('searchQuery', newValue)
        },
        async handleSelectedAccount(accountId)
        {
            this.setSpecificFilter('accountId', accountId)
        },
        async handleCourseStatus(status) {
            if(status === 'deleted') { // If on the deleted filter show only the all accounts option
                this.accountOptions = [{
                    displayText:
                        PROVIDER.Portfolio.PortfolioOverview.FilterInputs.SelectAccount.SelectOption
                            .AllAccounts,
                    value: ''
                }]

            }else { // else show all of them
                this.setAccountOptions()
            }

            this.setSpecificFilter('courseStatus', status)
        },
        async handleProductTypes(type) {
            this.setSpecificFilter('productType', type)
        },
        async getAccounts()
        {
            try 
            {
                const response = await getItems(
                    process.env.VUE_APP_ACCOUNT_API_URL,
                    'accounts',
                    1,
                    99999,
                    null,
                    null,
                    false
                )

                return response
            }
            catch (error) 
            {
                console.error('Something went wrong while retrieving accounts', error)
            }
        },
        async getAssortedProductsForAccountOptions()
        {
            const filterAssortedProducts = {
                search: '',
                top: 0,
                skip: 0,
                facets: ['accountId,count:1000'], // MD: Will this not work if we have more than 1k accounts?
                filter: `providerId eq '${this.selectedFilters.providerId}'`
            }
            try 
            {
                const response = await getItemsFromSearchIndex(
                    process.env.VUE_APP_ACCOUNT_API_URL,
                    API_CALL_URL_PATHS.assortedProducts,
                    API_CALL_URL_PATHS.search,
                    filterAssortedProducts
                )

                return response
            } 
            catch (error) 
            {
                console.error('Something went wrong while retrieving assorted products', error)
            }
        },
        async setAccountOptions()
        {
            if(!this.isAdminHasSelectedProvider && !this.isProvider)
            {
                return
            }
                // get accounts
                const accountsResponse = await this.getAccounts()
    
                // get assorted products
                const assortedProductsResponse = await this.getAssortedProductsForAccountOptions()

                if(assortedProductsResponse === null ||
                assortedProductsResponse.facets === null ||
                assortedProductsResponse.facets.accountId.length === 0)
                {
                    this.accountOptions = 
                    [
                        {
                            displayText:
                                PROVIDER.Portfolio.PortfolioOverview.FilterInputs.SelectAccount
                                    .SelectOption.NoAccounts,
                            value: ''
                        }
                    ]
                    return
                }
                // Only relevant accounts
                const accountsFromServer = accountsResponse.items.map((account) => {
                    return {
                        displayText: account.name,
                        value: account.id
                    }
                })

                const assortedProductAccountIds = assortedProductsResponse.facets.accountId.map(
                    (accountIdFacet) => {
                        return accountIdFacet.value
                    }
                )

                const filteredAccounts= accountsFromServer.filter((account) => 
                {
                    return assortedProductAccountIds.includes(account.value)
                })

                const newAccounts = [
                    {
                        displayText:
                            PROVIDER.Portfolio.PortfolioOverview.FilterInputs.SelectAccount.SelectOption
                                .AllAccounts,
                        value: ''
                    }
                ]
                this.accountOptions = [...newAccounts, ...filteredAccounts]
        }
    }
}
</script>
