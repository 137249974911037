<template>
	<div>
		<recess-table-flex class="qa-table-course-list u-overflow-inherit" :table-options="setTableOptions" :pagination-options="setPaginationOptions" @getData="(payload) => updateList(payload, true)">
			<recess-table-flex-row>
				<recess-table-flex-cell class="d-flex align-items-start justify-content-between">
					<div class="w-70">
						<div v-if="isCheckedAll">
							<p>
								{{ PROVIDER.Portfolio.PortfolioOverview.ExportAllPortfolios.Text }}
								<recess-button
									:title="`${PROVIDER.Portfolio.PortfolioOverview.ExportAllPortfolios.Button} (${setPaginationOptions.totalCount})`"
									variant="variant4"
									class="mr-3 u-fw-semi-bold qa-select-all-courses-list-button"
									@click.native.prevent="downloadPortfolioList(true)"
								/>
							</p>
						</div>
					</div>

					<div class="d-flex justify-content-end">
						<recess-button
							variant="secondary"
							:title="PROVIDER.Portfolio.PortfolioOverview.ExportPortfolioButton"
							class="qa-download-portfolio-list mr-3"
							@click.native.prevent="downloadPortfolioList()"
						/>
  
						<recess-button
							variant="secondary"
							:title="BUTTON_TEXT.editInGroup"
							:disabled="selectedCourses.length === 0 || setSelectedFilters.courseStatus === 'deleted'"
							class="qa-open-edit-courses-in-group-popup-button"
							@click.native.prevent="editCoursesInGroupHandler()"
						/>
					</div>
				</recess-table-flex-cell>
			</recess-table-flex-row>

			<recess-table-flex-head :set-fixed-header="true">
				<recess-table-flex-row>
					<recess-table-flex-head-item cell-size="narrow">
						<recess-checkbox-input
							v-model="isCheckedAll"
							name="select-all-courses-per-page"
							class="qa-select-all-courses-per-page"
							variant="secondary"
							:disabled="emptyCourseList"
							@input="(newValue) => selectAllCoursesPerPageHandler(newValue)"
						/>
					</recess-table-flex-head-item>

					<recess-table-flex-head-item
						v-for="header in headers"
						:key="header.key"
						:cell-size="header.cellSize"
						:sortable="header.isSortable"
						:sort-key="header.key"
						@emitSortableKey="emitSortBy(header.key)"
					>
						<p class="m-0">{{ header.title }}</p>
						<recess-tooltip v-if="header.title === 'Prijs'" class="ml-1">
							<template slot="content">
								<div>{{ PROVIDER.Portfolio.PortfolioOverview.TableHeaders.PriceTooltip }}</div>
							</template>
						</recess-tooltip>
					</recess-table-flex-head-item>

					<recess-table-flex-head-item cell-size="small" />
				</recess-table-flex-row>
			</recess-table-flex-head>

			<recess-table-flex-body>
				<recess-table-flex-row v-for="(course, courseIndex) in courses" :key="courseIndex">
					<recess-table-flex-cell cell-size="narrow">
						<recess-checkbox-input
							v-model="course.isChecked"
							name="select-one-course-course-list"
							:default-checked="course.isChecked"
							:class="`qa-select-one-course-course-list-${courseIndex}`"
							variant="secondary"
							@input="(newValue) => checkOneCourseHandler(course.id, courseIndex)"
						/>
					</recess-table-flex-cell>

					<recess-table-flex-cell :data-label="headers[0].title" cell-size="large">
						<router-link
							:to="{ name: 'course-details', params: { id: `${course.id}` }, query: { tab: 'course-algemeen' } }"
							class="qa-course-list-title u-text-semi-bold u-line-height-med"
							>{{ course.name }}</router-link
						>

						<span class="d-flex u-text-semi-bold">{{ course.externalId }} </span>
					</recess-table-flex-cell>

					<recess-table-flex-cell :data-label="headers[1].title" class="qa-course-list-cost">
						{{ course.amountWithoutTaxWithoutDiscount | currency }}
					</recess-table-flex-cell>

					<recess-table-flex-cell :data-label="headers[2].title" cell-size="medium" class="qa-course-list-productTypeDisplayValue">
						{{ course.productTypeDisplayValue }}
					</recess-table-flex-cell>

					<recess-table-flex-cell :data-label="headers[3].title" class="qa-course-list-source">
						{{ course.source }}
					</recess-table-flex-cell>

					<recess-table-flex-cell cell-size="small" :data-label="headers[4].title" class="qa-course-list-modifiedDate">
						{{ course.modifiedDate | formatDate }}
					</recess-table-flex-cell>

					<recess-table-flex-cell cell-size="small">
						<div class="d-flex align-items-start">
							<recess-dropdown variant="secondary" :has-dot-icon="true" :class="`d-inline-flex align-self-end qa-course-actions-dotted-menu-${courseIndex}`">
								<template v-slot:dropdown-content>
									<div class="flex-column d-flex align-items-start">
										<!-- Reactivate Course -->
										<recess-button
											v-if="isExpiredCourse(course.deleted, course.endDate)"
											variant="variant4"
											:title="PROVIDER.Portfolio.PortfolioOverview.ReactivateCourse"
											:class="`mb-1 u-color--primary u-fw-bold qa-reactivate-course-${courseIndex}`"
											@click.native.prevent="reactivateCourseHandler(course.id)"
										/>

										<!-- Open Price per Account popup -->
										<recess-button
											v-if="!isExpiredCourse(course.deleted, course.endDate)"
											variant="variant4"
											:title="PROVIDER.Portfolio.PortfolioOverview.ShowAssortedProductsList"
											:class="`mb-1 u-color--primary u-fw-bold qa-show-assorted-products-${courseIndex}`"
											@click.native.prevent="showHideAssortedProductsListPopUp(course.id)"
										/>

										<!-- Copy Course -->
										<recess-button
											v-if="!isExpiredCourse(course.deleted, course.endDate)"
											variant="variant4"
											:title="PROVIDER.Portfolio.PortfolioOverview.CopyCourse"
											:class="`mb-1 u-color--primary u-fw-bold qa-copy-course-${courseIndex}`"
											@click.native.prevent="copyCourseHandler(course.id)"
										/>

										<!-- Delete Course -->
										<recess-button
											v-if="!isExpiredCourse(course.deleted, course.endDate)"
											variant="variant4"
											:title="PROVIDER.Portfolio.PortfolioOverview.DeleteCourse"
											:class="`u-color--primary u-fw-bold qa-course-list-delete-button-${courseIndex}`"
											@click.native.prevent="deleteCourseHandler(course.id)"
										/>
									</div>
								</template>
							</recess-dropdown>
						</div>
					</recess-table-flex-cell>
				</recess-table-flex-row>
			</recess-table-flex-body>
		</recess-table-flex>

		<assorted-products-list-popup-component
			v-if="isAssortedProductsListPopUpVisible"
			:course-id="courseId"
			:is-modal-visible="isAssortedProductsListPopUpVisible"
			@close="showHideAssortedProductsListPopUp"
		/>

		<edit-duplicated-course-popup-component v-if="isEditDuplicatedCoursesVisible" :course-id="courseId" :is-modal-visible="isEditDuplicatedCoursesVisible" @close="closeEditDuplicatedCourse" />

		<delete-course-popup-component v-if="isDeleteCourseModalVisible" :is-modal-visible="isDeleteCourseModalVisible" :has-course-details="hasCourseDetails" @close="closeDeleteCourseModal" />

		<reactivate-course-popup-component :course-id="courseId" :is-modal-visible="isReactivateCourseModalVisible" @close="closeReactivateCourseModal" />

		<edit-courses-in-group-pop-up-component 
			:selected-filter="setSelectedFilters.courseStatus"
			:courses="selectedCourses" 
			:is-modal-visible="isEditCoursesInGroupModalVisible" 
			@close="closeEditCourseInGroupModal" 
		/>
	</div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { saveAs } from 'file-saver'
import { getItemsFromSearchIndex, postItem } from '@/../../shared/api/SharedClient'
import { PAGINATION, BUTTON_TEXT, WAIT_TIME, API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { minimumSelectionExportError } from '../../../../../shared/constants/validationMessageHelper'

import { sortBy } from '@/../../shared/utils/sorting'
const AssortedProductsListPopupComponent = () => import('@/components/molecules/AssortedProductsListPopupComponent')
const EditDuplicatedCoursePopupComponent = () => import('@/components/molecules/EditDuplicatedCoursePopupComponent')
const DeleteCoursePopupComponent = () => import('@/components/molecules/DeleteCoursePopupComponent')
const EditCoursesInGroupPopUpComponent = () => import('@/components/molecules/EditCoursesInGroupPopUpComponent')
const ReactivateCoursePopupComponent = () => import('@/components/molecules/ReactivateCoursePopupComponent')

import tableMixin from '@/../../shared/mixins/tableMixin'

export default {
	name: 'CourseList',
	components: {
		AssortedProductsListPopupComponent,
		DeleteCoursePopupComponent,
		EditCoursesInGroupPopUpComponent,
		EditDuplicatedCoursePopupComponent,
		ReactivateCoursePopupComponent
	},
	mixins: [tableMixin],
	props: {
		setSelectedFilters: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	data() {
		let paginationOptions =
			[
				{ displayText: '5', value: 5 },
				{ displayText: '10', value: 10 },
				{ displayText: '25', value: 25 },
				{ displayText: '50', value: 50 },
				{ displayText: '100', value: 100 },
				{ displayText: '250', value: 250 }
			]

		return {
			PROVIDER,
			PAGINATION,
			BUTTON_TEXT,
			WAIT_TIME,
			isDeleteCourseModalVisible: false,
			isReactivateCourseModalVisible: false,
			isEditCoursesInGroupModalVisible: false,
			isEditDuplicatedCoursesVisible: false,
			isAssortedProductsListPopUpVisible: false,
			timeout: null,
			courses: [],
			assortedProducts: [],
			selectedCourses: [],
			isCheckedAll: false,
			headers: [
				{
					key: 'name',
					title: PROVIDER.Portfolio.PortfolioOverview.TableHeaders.Name,
					cellSize: 'large',
					isSortable: true
				},
				{
					key: 'amountWithoutTaxWithoutDiscount',
					title: PROVIDER.Portfolio.PortfolioOverview.TableHeaders.Price,
					isSortable: true
				},
				{
					key: 'productTypeDisplayValue',
					title: PROVIDER.Portfolio.PortfolioOverview.TableHeaders.ProductType,
					cellSize: 'medium',
					isSortable: true
				},
				{
					key: 'source',
					title: PROVIDER.Portfolio.PortfolioOverview.TableHeaders.Source,
					isSortable: true
				},
				{
					key: 'modifiedDate',
					title: PROVIDER.Portfolio.PortfolioOverview.TableHeaders.ModifiedDate,
					cellSize: 'small',
					isSortable: true
				}
			],
			filter: {
				search: '',
				top: 0,
				skip: 0,
				orderBy: ['modifiedDate desc'],
				filter: 'deleted eq false',
				searchMode: 'all'
			},
			courseId: null,
			sortOption: {
				sortKey: null,
				sortDirection: null
			},

			// TABLE AND PAGINATION OPTIONS - REQUIRED
			setTableOptions: {
				title: PROVIDER.Portfolio.PortfolioOverview.TableTitle,
				setCellSizes: true
			},
			setPaginationOptions: {
				showTopPagination: true,
				showBottomPagination: true,
				currentPage: this.$route.query.page !== undefined ? Math.round(Number(this.$route.query.page)) : 1,
				totalCount: 0,
				selectOptions: paginationOptions,
				defaultTotalItemsPerPage: paginationOptions[3].value,
				itemsPerPageFromUrlQuery: this.$route.query.itemsPerPage !== undefined ? Number(this.$route.query.itemsPerPage) : paginationOptions[3].value,
				showSelectOption: true,
				selectOptionsLabel: PAGINATION.selectOptionsLabel,
				paginationShowLabel: PAGINATION.paginationShowLabel,
				paginationOfLabel: PAGINATION.paginationOfLabel
			}
		}
	},
	computed: {
		shouldCallCourseIndex() {
			return !this.setSelectedFilters.accountId || this.setSelectedFilters.courseStatus === 'deleted'
		},
		isCourseDeleted() {
			return this.courses.some((course) => course.deleted === true)
		},
		isAllCoursesChecked() {
			return this.courses && this.courses.length > 0 && this.courses.every((course) => course.isChecked)
		},
		emptyCourseList() {
			return this.courses.length === 0
		},
	},
	watch: {
		isAllCoursesChecked: {
			handler() {
				this.setIsCheckedAllStatus()
			}
		}
	},
	created() {
		this.$nextTick(() => {
			this.$bus.on('refreshCourseList', this.getCoursesAfterDeleteCourse)
		})
	},
	mounted() {
		if (!this.$route.query.page || !this.$route.query.itemsPerPage) {
			this.updateList({ currentPage: 1, itemsPerPage: this.setPaginationOptions.itemsPerPageFromUrlQuery })
			return
		}

		this.updateList({ currentPage: this.$route.query.page, itemsPerPage: this.$route.query.itemsPerPage })
	},
	beforeDestroy() {
		this.$bus.off('refreshCourseList')
		clearTimeout(this.timeout)
	},
	methods: {
		//#region Start - Get courses list
		emitSortBy(headerKey) {
			this.sortOption = sortBy({ sortOption: this.sortOption, key: headerKey })

			this.updateList({ currentPage: 1, itemsPerPage: this.setPaginationOptions.itemsPerPageFromUrlQuery })
		},
		updateList({ currentPage, itemsPerPage }, paginationChange = false) {
			this.setPaginationOptions.itemsPerPageFromUrlQuery = itemsPerPage
			this.setPaginationOptions.currentPage = currentPage

			this.filter.top = itemsPerPage
			this.filter.skip = currentPage * itemsPerPage - itemsPerPage

			this.getSelectedSearch()

			if (this.shouldCallCourseIndex) {
				this.getSelectedSort()
				this.getSelectedCourseStatus()
				this.getSelectedProvider()
				this.getSelectedProductType()
				this.getDataOnPageChange() // Make call to course index
			} else {
				if(paginationChange) { // No need to do another call to assortments if it was a pagination change
					this.courses = this.assortedProducts.slice(this.filter.skip, this.filter.top + this.filter.skip)
					this.setCurrentPageAsQueryParam()
					return
				}

				this.filter.filter = ''
				this.getSelectedSort(true)
				this.getSelectedProvider(false)
				this.getSelectedProductType()
				this.getSelectedAccount()
				this.getAssortedProductsData() // Make call to assortments
			}
		},
		getSelectedSort(isAssortment = false) {
			let renamedKey = '';

			if (!this.sortOption.sortKey) return

			if (isAssortment) {
				const keysMap = {
					name: 'title',
					amountWithoutTaxWithoutDiscount: 'amountWithoutVatWithDiscount'
				}

				if (keysMap.hasOwnProperty(this.sortOption.sortKey)) {
					renamedKey = keysMap[this.sortOption.sortKey];
				}
			}

			if (renamedKey) {
				this.filter.orderBy = [`${renamedKey} ${this.sortOption.sortDirection}`]
			} else {
				this.filter.orderBy = [`${this.sortOption.sortKey} ${this.sortOption.sortDirection}`]
			}

		},
		concatenateFilterString() {
			if (this.filter.filter.length > 0) {
				this.filter.filter += ` and `
			}
		},
		getSelectedAccount() {
			if (!this.setSelectedFilters.accountId) return

			this.concatenateFilterString()
			this.filter.filter += `accountId eq '${this.setSelectedFilters.accountId}'`
			
		},
		getSelectedCourseStatus() {
			const currentDate = new Date().toJSON().slice(0, 10)

			if (this.setSelectedFilters.courseStatus === 'active') {
				this.filter.filter = `(deleted eq false and (endDate eq null or endDate gt ${currentDate}))`
			} else if (this.setSelectedFilters.courseStatus === 'deleted') {
				this.filter.filter = `(deleted eq true or (endDate ne null and endDate lt ${currentDate}))`
			} else {
				this.filter.filter = ''
			}
		},
		getSelectedProductType() {
			if (this.setSelectedFilters.productType !== 'All') {
				this.concatenateFilterString()
				this.filter.filter += `productType eq '${this.setSelectedFilters.productType}'`
			}
		},
		getSelectedSearch() {
			const searchValue = this.setSelectedFilters.searchQuery ? `${this.setSelectedFilters.searchQuery}*` : ""
			this.filter.search = searchValue
		},
		getSelectedProvider(concatenate = true) {
			if (!(this.setSelectedFilters && this.setSelectedFilters.providerId)) return

			if(concatenate) this.concatenateFilterString()

			this.filter.filter += `providerId eq '${this.setSelectedFilters.providerId}'`
		},
		getDataOnPageChange() {
			this.resetSelectAllCourses()
			this.getCourses()
			this.setCurrentPageAsQueryParam()
		},
		async getAssortedProducts()
        {
			let filter = JSON.parse(JSON.stringify(this.filter))
			filter.top = 1000
			filter.skip = 0
			filter.searchFields = ['externalId', 'title']

            try 
            {
                const response = await getItemsFromSearchIndex(
                    process.env.VUE_APP_ACCOUNT_API_URL,
                    API_CALL_URL_PATHS.assortedProducts,
                    API_CALL_URL_PATHS.search,
                    filter
                )
                if (!response)
                {
                    return
                }

				this.courses = []
				this.assortedProducts = []

                response.results.forEach((result) => {
					const mappedCourse = result.document

					mappedCourse.name = mappedCourse.title
					mappedCourse.learningMethodDisplayValue = mappedCourse.learningMethodTypeDisplayValue
					mappedCourse.amountWithoutTaxWithoutDiscount = mappedCourse.amountWithoutVatWithDiscount
					mappedCourse.id = mappedCourse.productReference.split(':')[1]
					
                    // this.courses.push(mappedCourse)
					this.assortedProducts.push(mappedCourse) // Test contains ALL assorted prod for that account
                })
                return response
            } 
            catch (error) 
            {
                console.error('Something went wrong while retrieving assorted products', error)
            }
        },
		async getAssortedProductsData() {
			this.resetSelectAllCourses()

			try {
				const response = await this.getAssortedProducts()

				// MD: keep querying index to get every record
				if (response && response.count > 1000) 
				{
					const promises = []
		
					const iterations = Math.ceil(response.count / 1000) // MD: round up, if 1.2 we want 2 iterations
					for (let i = 1; i < iterations; i++) 
					{
						this.filter.skip = 1000 * i
						promises.push(this.getAssortedProducts())
					}
					await Promise.all(promises)
				}

				let uniqueKeys = new Set();

				// Create a new array with unique elements
				let uniqueArray = this.assortedProducts.filter(obj => {
				// If the key is not in the Set, add it and return true
				if (!uniqueKeys.has(obj.id)) {
					uniqueKeys.add(obj.id);
					return true;
				}
		
				return false;
				})

				this.courses = uniqueArray.slice(this.filter.skip, this.filter.top + this.filter.skip );
				this.setPaginationOptions.totalCount = uniqueArray.length
				this.setCurrentPageAsQueryParam()
			}
			catch (error)
			{
				console.error('Something went wrong while retrieving assorted products from index in a second or more iteration', error)
			}
		},
		async getCourses() {
			try {
				const filter = JSON.parse(JSON.stringify(this.filter))

				const response = await getItemsFromSearchIndex(
					process.env.VUE_APP_PROVIDER_API_URL,
					API_CALL_URL_PATHS.courses,
					API_CALL_URL_PATHS.search,
					filter)
				if (!response) {
					return
				}

				this.setInitialCheckboxState(response.results)

				this.setPaginationOptions.totalCount = response.count

			}
			catch (error) {
				console.error('Something went wrong while retrieving courses', error)
			}
		},
		//#endregion
		//#region Duplicate course methods
		async copyCourseHandler(courseId) {
			this.courseId = courseId
			this.isEditDuplicatedCoursesVisible = true
		},
		closeEditDuplicatedCourse() {
			this.isEditDuplicatedCoursesVisible = false
		},
		showHideAssortedProductsListPopUp(courseId) {
			this.courseId = courseId
			this.isAssortedProductsListPopUpVisible = !this.isAssortedProductsListPopUpVisible
		},
		//#endregion
		isExpiredCourse(courseIsDeleted, courseEndDate) {
			const currentDate = new Date().toJSON().slice(0, 10)
			return courseIsDeleted === true || (courseEndDate != null && courseEndDate < currentDate)
		},
		showDeleteCourseModal() {
			this.isDeleteCourseModalVisible = true
		},
		closeDeleteCourseModal() {
			this.isDeleteCourseModalVisible = false
		},
		async reactivateCourseHandler(courseId) {
			this.courseId = courseId
			this.showReactivateCourseModal()
		},
		showReactivateCourseModal() {
			this.isReactivateCourseModalVisible = true
		},
		closeReactivateCourseModal() {
			this.isReactivateCourseModalVisible = false
		},
		closeEditCourseInGroupModal() {
			this.isEditCoursesInGroupModalVisible = false
			this.setIsCheckedAllStatus()
		},
		getCoursesAfterDeleteCourse() {
			this.timeout = setTimeout(() => {
				if (this.shouldCallCourseIndex) {
					this.getDataOnPageChange()
				}else {
					this.getAssortedProductsData()
				}

			}, WAIT_TIME.indexRebuild)
		},
		async deleteCourseHandler(courseId) {
			if (!courseId) return
			this.hasCourseDetails = {
				courseId,
				isInCourseDetailsPage: false,
				isInAssortment: false
			}

			await this.checkIfCourseIsUsed(courseId)

			this.showDeleteCourseModal()
		},
		async checkIfCourseIsUsed(courseId) {
			const filter = {
				search: '',
				top: 0,
				skip: 0,
				orderBy: ['modifiedDate desc'],
				filter: `productReference eq 'course:${courseId}'`,
				searchMode: 'all'
			}
			const response = await getItemsFromSearchIndex(process.env.VUE_APP_ACCOUNT_API_URL, API_CALL_URL_PATHS.assortedProducts, API_CALL_URL_PATHS.search, filter)
			this.hasCourseDetails.isInAssortment = response.count > 0
		},
		resetSelectAllCourses() {
			this.selectedCourses = []
			this.isCheckedAll = false
		},
		setInitialCheckboxState(results) {
			this.courses = [] // reset courses for filtering again
			results.map((result) => {
				const courseItem = result.document
				// set isChecked status every time when course list is refreshed
				this.setIsCheckedStatus(courseItem)
				this.courses.push(courseItem)
				// set selectAll button status every time when course list is refreshed
				this.setIsCheckedAllStatus()
				return null
			})
		},
		setIsCheckedStatus(courseItem) {
			const isCourseChecked = this.selectedCourses.find((selectedCourse) => selectedCourse.courseId === courseItem.id)
			if (isCourseChecked) {
				courseItem.isChecked = true
			} else {
				courseItem.isChecked = false
			}
		},
		setIsCheckedAllStatus() {
			if (this.isAllCoursesChecked) {
				this.isCheckedAll = true
			} else {
				this.isCheckedAll = false
			}
		},
		editCoursesInGroupHandler() {
			this.isEditCoursesInGroupModalVisible = true
		},
		selectAllCoursesPerPageHandler() {
			this.courses.map((course) => {
				// only add courses that are not selected yet to 'selectedCourses' array
				if (this.isCheckedAll && !course.isChecked) {
					course.isChecked = true

					const courseDetails = {
						courseId: course.id
					}
					this.selectedCourses.push(courseDetails)
				}

				if (!this.isCheckedAll) {
					this.selectedCourses = this.selectedCourses.filter((selectedCourse) => selectedCourse.courseId !== course.id)
					course.isChecked = false
				}
				return null
			})
		},
		checkOneCourseHandler(courseId, index) {
			if (this.courses[index].isChecked) {
				const courseDetails = {
					courseId
				}
				this.selectedCourses.push(courseDetails)
			} else {
				this.selectedCourses = this.selectedCourses.filter(selectedCourse => selectedCourse.courseId !== courseId)
				this.isCheckedAll = false
			}
		},
		getSelectedCourseId() {
			if (this.selectedCourses.length === 0) return

			let selectedIds = ''
			this.selectedCourses.forEach(course => {
				if (selectedIds.length > 1) {
					selectedIds += ` or `
				}

				selectedIds += `id eq '${course.courseId}'`
			})

			return selectedIds
		},
		mapCorrectKeysToExport() {
			const keysMap = {
				title: 'name',
				learningMethodTypeDisplayValue: 'learningMethodDisplayValue',
				amountWithoutVatWithDiscount: 'amountWithoutTaxWithoutDiscount'
			}

			let renamedKey = ''
			if (keysMap.hasOwnProperty(this.sortOption.sortKey)) {
				renamedKey = keysMap[this.sortOption.sortKey];
			}

			return renamedKey
	
		},
		async downloadPortfolioList(triggerExportAll) {
			const exportFilterToApply = JSON.parse(JSON.stringify(this.filter))

			// Map the orderBy to correct values in case we are showing the ass prod.
			if(this.sortOption.sortKey) {
				const renamedKey = this.mapCorrectKeysToExport()
				exportFilterToApply.orderBy = [`${renamedKey} ${this.sortOption.sortDirection}`]
			}

			let urlPath = {
				api: process.env.VUE_APP_PROVIDER_API_URL,
				endpoint: API_CALL_URL_PATHS.downloadPortfolio
			}

			if (this.setSelectedFilters.accountId && triggerExportAll) {
				urlPath = {
					api: process.env.VUE_APP_ACCOUNT_API_URL,
					endpoint: API_CALL_URL_PATHS.exportByAccount
				}

				exportFilterToApply.accountId = this.setSelectedFilters.accountId
			}


			const currentDate = new Date().toJSON().slice(0, 10)
			const defaultFilter = `(deleted eq false and (endDate eq null or endDate gt ${currentDate})) and providerId eq '${this.setSelectedFilters.providerId}'`

			if (triggerExportAll && triggerExportAll !== undefined && this.setSelectedFilters.accountId) {
				exportFilterToApply.top = 0
				exportFilterToApply.filter = `(endDate eq null or endDate gt ${currentDate}) and providerId eq '${this.setSelectedFilters.providerId}' and accountId eq '${this.setSelectedFilters.accountId}'`
			}
			// Provider is not selected (when you login as admin)
			else if (triggerExportAll && triggerExportAll !== undefined && !this.setSelectedFilters.providerId) {
				exportFilterToApply.top = 0
				exportFilterToApply.filter = `(deleted eq false and (endDate eq null or endDate gt ${currentDate}))`
			}
			else if (triggerExportAll && triggerExportAll !== undefined && this.setSelectedFilters.providerId && !this.setSelectedFilters.accountId) {
				exportFilterToApply.top = this.setPaginationOptions.totalCount
				exportFilterToApply.filter = defaultFilter
			}
			else {
				exportFilterToApply.filter = this.getSelectedCourseId()
			}

			if (triggerExportAll === undefined && (exportFilterToApply.filter === undefined || exportFilterToApply.filter === defaultFilter)) {
				const toastNotification = {
					type: 'error',
					message: minimumSelectionExportError('cursus')
				}

				this.$store.dispatch('toastNotificationModule/add', toastNotification, {
					root: true
				})

				return
			}


			const response = await postItem(urlPath.api, urlPath.endpoint, exportFilterToApply, false, 'arraybuffer')
			try {
				if (!response) return
				const mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				const blob = new Blob([response], { type: mediaType })
				const filename = `ExportPortfolioList-${currentDate}.xlsx`
				saveAs(blob, filename)
			} catch (error) {
				console.error('Something went wrong while retrieving the assorted products import error list excel', error)
			}
		}
	}
}
</script>
